@media (max-width: 767.98px) {
body {
        min-width: initial; 
}

.AchatMenu {
  gap: 0!important;
  zoom: .7;
}
.btn {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    font-size: 13px;
    padding: 0 15px;
    background-color: #ffffff;
    border: none;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}
p{
    font-size: 16px!important;
}
.d-none-m{
    display:none;
}
.top-header > .container{
    min-height: 70px;
    height:70px;
    padding:10px;
 
}

.top-header-content {
    flex-direction: column-reverse!important;
}
.positionimg{
    position: absolute;
    top: 313px;
    left: 195px;
    width: 150px;
}
.rightmenu {
    gap:10px!important;
}
.top-header-content-txt {
    align-items: center;
    gap: 10px;
    max-width: 90%;
}
.top-header-content-txt img{
    max-width: 90%;
}
.gap200 {
    /*zoom: 0.6;*/
    overflow: hidden;
    gap:10px;
}
section img{
    zoom:.6;
}
.txt-2{
    text-align: center;
}
.gap128{
    gap:28px;
    flex-direction: column-reverse!important;
}
.flexbox-center-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.PackDetail-details > button {
    min-width: 130px;
}
.s2 > h2 {
    font-size: 27px;
}
.TagsFilter{
    
    flex-direction: column;
}
.s2-mobile{
    overflow: hidden;
}
.page-title {
    line-height: initial;
    font-size:16px;
}
.packheader {
    flex-direction: column;
}
.packheader > img {
  zoom: 0.9;
}
.packdetails-title {
  
  font-size: 30px;
}
.packdetails-price {
  font-size: 40px;
}
.MarketPlaceFocus-title{
  font-size: 25px;
}
.footer-menu a ,.footer-z3 a{
    font-size:12px;
}
.footer-z3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
 }
 .auth-form-image img{
     width: 40px;
 }
 .btn-google{
    padding: 0 0 0 40px;
 }
 .SpoowContainer {
    
    flex-direction: column;
 }
 
 .UserContainer {
    
    flex-direction: column;
    align-items: center;
    gap:20px;
}
.cardinfos {
  gap: 0;
  flex-direction: column;
}
.userform .btn{
  color:#fff;
}
.userbtnphoto {
order:-1;
display:none!important;
}
.footer-logo {
    flex:initial;
    width: 100%;
}
.footer-z1 {
    
    gap: 30px;
    flex-wrap: wrap;
}
.modal-body {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
}
.UserMenu {
    gap: 35px;
}
.UserMenu > span {
    font-size: 20px;
}
.header .menu-btn:checked ~ .menu-icon .navicon{
    display: none;
}


.bloc-partenaire{
    overflow: scroll;
    width: 95%;
    padding-bottom: 20px;
}


/*style menu-right */
.header {
    position: fixed;
    z-index: 3;
    left: 15px;
    right: 15px;
   
   
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #25252F;
 
    left: 5px;
    right: 5px;
    bottom: 5px;
    top: 5px;
  }
  .header a{
    font-size: 25px;
    color: #fff;
    text-decoration: none
  }
  
  .header li a {
    display: block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
    ;
    
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
    background-color: #f4f4f4;
  }
  
  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }
  
  /* menu */
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 5px;
    position: relative;
    transition: background .2s ease-out;
    width: 37px;
    top: -2px;
   
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
    
  }
  
  .header .menu-icon .navicon:before {
    top: 10px;
  }
  
  .header .menu-icon .navicon:after {
    top: -10px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 100vh;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */
  
  @media (min-width: 48em) {
    .header li {
      float: left;
    }
    .header li a {
      padding: 20px 30px;
    }
    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .header .menu-icon {
      display: none;
    }
  }
/*end style menu-right*/

}
 @media (min-width: 767.99px) { 

    .d-none-d{
        display:none;
    }
    .w-L{
      flex:1;
    }
  }
  @media (max-width: 380px) { 
    .positionimg ,.img1-m {
        zoom: .8;

    }
    .top-header-content-txt{
        gap:0;
    }
    .s2 > h2 {
        font-size: 20px;
    }



   }


   .userform input::placeholder {
    color: #fff;
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 24px;
  }

 .userbtnphoto{
   margin-top: -65px;
   cursor: pointer;
   border: 2px solid #fff;
   background-color: rgb(255 255 255 / 15%);
   padding:2px 15px;
   height:30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   float: right;
   width:225px;
 }
 .userbtnphoto a{
  
  font-size:14px;
  font-family: "poppins";
  font-weight:500;
  color:#fff;
  text-decoration: none;


 }
 .modalVente{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
 .modalVente h3{
   font-size: 19px;
   font-weight:700;
   color:#fff;
   font-family: "poppins";
   text-transform: uppercase;
   text-align: center;
   line-height: 35px;
 }
 .modalVente a{
  font-size: 19px;
  font-weight:400;
  text-decoration: underline;
  color:#fff;

}
.ellipse{
  position:relative;
}
.checkE{
  position: absolute;
  top: 100px;
  left: 50px;
}


.PackDetail .css-qc6sy-singleValue{
  color:#fff;
  font-size:35px;

}
.PackDetail .css-1hb7zxy-IndicatorsContainer{
  background-color: #C4C4C4;
    width: 70px;
    border:none;
    height:61px;

}
.PackDetail .css-tj5bde-Svg{
  fill:#C4C4C4;
}

.priceAchat{
  min-width: 200px;
}
