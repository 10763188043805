body,
html {
  margin: 0;
  padding: 0;
  background: #111117;
}

body {
  /*min-width: 1260px;*/

  background: #111117;
  font-family: Poppins;
  font-style: normal;
}

.container {
  max-width: 1128px;
  margin: 0 auto;
  width: 95%;
}

.top-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.28);
}

.top-header > .container {
  min-height: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  width: 90%;
}

.header-container {
  position: relative;
  min-height: 150px;
}

.Header-highlight {
  width: 100%;
}

.btn {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  font-size: 15px;
  padding: 0 56px;
  background-color: #ffffff;
  border: none;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Poppins';
}

.btn-rounded {
  border-radius: 20px;
}

.btn-color {
  color: #ffffff;
  background: linear-gradient(94.35deg, #5b86e5 0%, #36d1dc 100%);
}

.rightmenu {
  display: flex;
  align-items: center;
  gap: 32px;
}

.top-header-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-header-content-txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  max-width: 490px;
}

.top-header-content-txt > p {
  font-family: Poppins;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

.header-container {
  background-color: #25252f;
}
section {
  padding: 55px 0;
}
section.color {
  background: linear-gradient(
    180deg,
    #25252f 67.49%,
    rgba(37, 37, 47, 0.130769) 133.98%
  );
}
.flexbox {
  display: flex;
}

.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap200 {
  gap: 200px;
}

.gap128 {
  gap: 128px;
}

.gap16 {
  gap: 16px;
}

.txt-1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 57px;
  color: #fff;
  text-align: center;
}

.s1 {
  padding: 55px 0 110px 0;
}

.txt-2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 55px;
  color: #fff;
  text-align: left;
}

.s2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.s2 > h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 57px;
  display: flex;
  gap: 16px;
  align-items: center;
  color: #fff;
  margin: 60px 0;
}

.mainpage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainpage > main {
  flex: 1;
}

footer {
  background: #25252f;
  padding: 60px 0;
  border-top: 2px solid #ccc;
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer-logo {
  flex: 1;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-menu a {
  text-decoration: none;
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.footer-z1 {
  display: flex;
  gap: 50px;
}

.txt-4 {
  color: #fff;
  margin-bottom: 15px;
  display: block;
}

.footer-z3 {
  display: flex;
  font-family: Poppins;
  align-items: center;
}

.footer-z3 a {
  text-decoration: none;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.sep {
  width: 1px;
  background-color: #fff;
  display: block;
  height: 20px;
}

.modal-btn-close {
  background: none;
  border: none;
}

.modal {
  z-index: 100000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.modal-body {
  transform: translateY(200px) rotateX(60deg) rotateY(60deg) scale(0.5);
  max-width: 800px;
  width: 90%;
  background-color: #25252f;
  min-height: 300px;
  padding: 50px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.modal-visible {
  visibility: visible;
  opacity: 1;
}

.modal-visible .modal-body {
  transform: translateY(0) rotateX(0) rotateY(0) scale(1);
}

.modal-btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  cursor: pointer;
}

.auth-form {
  display: flex;
}

.auth-form-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form > div {
  flex: 1;
}

.auth-form-form {
  display: flex;
  flex-direction: column;
}

.auth-form-title {
  text-transform: uppercase;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  color: #fff;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.input-field input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px 5px;
  outline: none;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.input-field label {
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.passeoublie {
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  mix-blend-mode: normal;
  align-self: center;
  margin: 30px 0;
  cursor: pointer;
}

.formsep {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.formsepspan {
  height: 1px;
  background-color: #fff;
  flex: 1;
}

.btn {
  position: relative;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.btn > .img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 50px;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.btn:active,
.btn:active > .img {
  background: rgba(0, 0, 0, 0.5) !important;
}

.firebaseloading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.UserMenu {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  position: relative;
}

.UserMenu > img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.UserMenu > span {
  color: #fff;
}

.UserMenu > .submenu {
  position: absolute;
  top: 110%;
  right: 0;
  width: 200px;
  background-color: #25252f;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transform: rotateX(60deg);
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.submenu-item {
  text-decoration: none;
  color: #fff;
  height: 54px;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  padding: 0 15px;
  gap: 15px;
  font-weight: normal !important;
  display: flex;
}

.submenu-item:hover {
  color: #25252f;
  background-color: #fff;
}

.UserMenu > .submenu-open {
  visibility: visible;
  transform: rotateX(0);
}
/*mise vente */
.miseVente{
  background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    
    outline: none;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.miseVente input{
  flex:1;
  background-color: transparent;
  border:none;
    padding: 10px 5px;
    outline: none;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
  
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.venteSpan{
  color: #c4c4c499;
  font-size: 20px;
  font-weight: 500;
}
.unitV{
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

/*Achat Menu*/
.BtnMiseVente{
  width: 80%;
  display: flex;
  flex-direction: column;
}
.Achatcomp{
  display: flex;
  flex-direction: column;
}
.AchatMenu {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: #25252f;
  margin-bottom: 20px;
  gap:100px;
}
.AchatMenu .material-icons{

 
  color:#fff;
  height:61px;
  width:70px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.AchatMenu > img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.AchatMenu > span {
  color: #fff;
  font-size:35px;
  
}
.priceSpan{
  padding-left:10px;
  font-weight: 700;
}

.AchatMenu > .submenu {
  position: absolute;
  z-index: 99;
  top: 95%;
  left: 0;
  right:0;
  background-color: #25252f;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transform: rotateX(60deg);
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.AchatMenu .submenu-item {
  text-decoration: none;
  color: #fff;
  height: 54px;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  padding: 0 15px;
  gap: 15px;
  font-weight: normal !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:10px 25px;
}
.AchatMenu .submenu-item div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:15px;
}
.priceAchat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.priceAchat .unit{
  font-size:20px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "poppins";

}


.AchatMenu .submenu-item:hover {
  color: #25252f;
  background-color: #fff;
}

.AchatMenu > .submenu-open {
  visibility: visible;
  transform: rotateX(0);
}


/*Fin Achat Menu */
.leftmenu {
  display: flex;
  flex: 1;
  gap: 30px;
  margin-left: 50px;
}

.leftmenu > a {
  text-decoration: none;
  color: #fff;
}

.userphoto {
  width: 164px;
  height: 169px;
  border-radius: 82px;
}

main {
  z-index: 1;
}

.userphoto-div {
  width: 164px;
  margin-top: -82px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 38px;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}

.UserContainer {
  display: flex;
  align-items: flex-start;
  gap: 108px;
  min-height: 100px;
  color: #fff;
}

.Header-highlight {
  display: block;
}

.page-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 20px;
  color: #ffffff;
  margin: 50px 0 20px 0;
}

.userform {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin-bottom: 50px;
}

.userformbtn {
  margin-top: 60px;
}

.cardinfos {
  display: flex;
  gap: 55px;
}

.cardinfos > * {
  flex: 1;
}

.SpoowContainer {
  display: flex;
  color: #fff;
}

.SpoowContainer > * {
  flex: 1;
}

.SpoowContainer .left-content {
  max-width: 265px;
  padding: 30px;
}

.MarketPlaceFilterItem {
  background-color: #25252f;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 3px 15px;
  display: flex;
  flex-direction: column;
}

.MarketPlaceFilterItem-title {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.MarketPlaceFilter {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.MarketPlaceFilterItem > .MarketPlaceFilterItem-content {
  transform: scaleY(0);
  max-height: 0;
  margin-top: 0;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.MarketPlaceFilterItem-open > .MarketPlaceFilterItem-content {
  transform: scaleY(1);
  max-height: 100%;
  margin-top: 15px;
}


.MarketPlaceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 215px;
  padding: 15px;
  cursor: pointer;
  background-color: transparent;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.MarketPlaceItem:hover {
  background-color: #000;
}

.MarketPlaceItem img {
  max-width: 190px;
}

.MarketPlaceItem-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  width: 100%;
}

.MarketPlaceItem-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  width: 100%;
}

.MarketPlaceItem-sep {
  height: 1px;
  background-color: #606068;
  width: 100%;
}

.MarketPlaceItem-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.partiede {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #3a86e0;
}

.pricevalue {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.MarketPlace-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px 0;
}

.ShowMore {
  height: 44px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 20px;
  color: #fff;
  background-color: transparent;
  margin: 20px 0;
  cursor: pointer;
}

.centerbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-layout-nav {
  color: #c0c0c5;
  margin: 15px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.TagsFilter {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
}

.TagsFilterItem {
  height: 28px;
  border-radius: 14px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
}

.TagsFilterItem-active {
  color: #000;
  background-color: #fff;
}

.PackDetail-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.PackDetail-item {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.PackDetail-item-active {
  border: 1px solid #fff;
}

.PackDetail-main {
  flex: 1;
}

.PackDetail {
  display: flex;
  color: #fff;
  gap: 20px;
  margin: 30px 0;
}

.PackDetail-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.PackDetail-date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 5px;
}

.PackDetail-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 43px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;
}

.PackDetail-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PackDetail-main > img {
  max-width: 100%;
}

.PackDetail-ref {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  color: #c4c4c4;
}
.PackDetail-details > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 46px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.PackDetail-details > button {
  min-width: 250px;
}

.PackDetail-info {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 46px;
  color: #ffffff;
}

.PackDetail-details > small {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}

.SepColor {
  height: 15px;
  background: linear-gradient(90deg, #F09819 16.15%, #EDDE5D 82.29%);
}

.MarketPlaceFocus-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 43px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;
}

.MarketPlaceFocus-subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;
}

.flexaroundrow {
  display: flex;
  align-items: center;
}

.MarketPlaceFocus-scores {
  min-width: 200px;
  font-size: 30px;
  display: flex;
  justify-content: space-around !important;
}

.MarketPlaceFocus-z2 {
  min-width: 200px;
  display: flex;
  justify-content: space-around !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 55px;
  color: #c4c4c4;
}

.txtcolor {
  color: #fff;
}

.flex1 {
  flex: 1;
}

.MarketPlaceFocus-z1 > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MarketPlaceFocus-line {
  width: 1px;
  background-color: #fff;
}

.MarketPlaceFocus-z3 {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.MarketPlaceFocus-z4 {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.fcenter {
  align-items: center;
  justify-content: center;
}

.dflex {
  display: flex;
}

.MarketPlaceFocusRow {
  display: flex;
  margin: 15px 0;
}

.MarketPlaceFocusRow > div {
  flex: 1;
  display: flex;
}

.MarketPlaceFocusRow-c2 {
  justify-content: center;
}

.MarketPlaceFocusRow-c3 {
  justify-content: flex-end;
}

.dfcol {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.MarketPlaceFocusContainer {
  max-width: 750px;
  margin: 30px auto;
}

.stats {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
}

.MarketPlaceFocus-info {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #c4c4c4;
  margin: 27px 0 7px 0;
}

.MarketPlaceFocus-infovalue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
}

.MarketPlaceItemsCarrousel-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.MarketPlaceItemsCarrousel-list > .MarketPlaceItem {
  background-color: #25252f;
}

.MarketPlaceItemsCarrousel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.NavLeftRight {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #fff;
}

.NavLeftRight-sep {
  height: 25px;
  width: 1px;
  background-color: #fff;
}

.NavLeftRightbtn {
  cursor: pointer;
}

.MarketPlaceItemsCarrousel-t {
  display: flex;
  gap: 30px;
}

.disablebtn {
  color: rgb(65, 65, 65);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

pre {
  background-color: #fff;
  color: #000;
  padding: 15px;
}

.packheader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px auto;
}

.packheader > img {
  max-width: 400px;
}

.packdetails {
  padding: 40px 32px;
  background: #25252f;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  color: #fff;
}

.packdetails-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 43px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;
}

.packdetails-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 55px;
  color: #ffffff;
}

.packdetails > button {
  width: 100%;
}

.packdetails-price > small {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  color: #ffffff;
}

.packdetails > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #C4C4C4;
}
